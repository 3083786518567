<template>
  <small class="time">
    <timeago
      :datetime="datetime"
      v-bind:converter-options="{
        format: 'PPP',
        forceDateFormat: true
      }"
    />
  </small>
</template>

<script>
export default {
  name: 'Time',
  props: {
    datetime: {
      type: [String, Number],
      default: null
    }
  }
}
</script>

<style lang="scss">
$time-gap: $small-font-size;

.time {
  display: block;
  margin: 0 0 $time-gap;
}
</style>
